import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { formatBackgroundAndFilter } from '@komo-tech/core/utils/css';
import { CSSProperties } from 'react';

export interface StyledBackgroundProps {
  image?: ImageDataModel;
  color?: string;
  filterRgb?: string;
}

export const backgroundStyle = (p: StyledBackgroundProps): CSSProperties => ({
  background: backgroundCssValue(p),
  backgroundColor: p.color ? p.color : '#FFFFFF'
});

export const backgroundCssValue = (
  p: Omit<StyledBackgroundProps, 'backgroundColor'>
) => `${formatBackgroundAndFilter({
  backgroundFilter: p.filterRgb,
  backgroundImageUrl: p.image?.url
})}
    no-repeat center center/cover fixed`;
