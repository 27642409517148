import { FC, memo } from 'react';

import { Box } from '@/common/components/Display/Box';
import { Image as ImageComponent } from '@/common/components/Image';
import { ImageDataModel } from '@komo-tech/core/models/ImageDataModel';
import { useTheme } from '@/theme';

import classes from './_Cover.module.css';
import { useFrontPage, useFrontSite } from './Store';

export const SiteCover: FC = memo(() => {
  const pageProperties = useFrontPage((x) => x.page?.properties);
  const siteProperties = useFrontSite((x) => x.site.properties);
  const properties = pageProperties ?? siteProperties;

  if (!properties.BillboardEnabled) {
    return null;
  }

  return <PageCover image={properties.BillboardImage} />;
});

interface Props {
  image: ImageDataModel;
}
export const PageCover: FC<Props> = ({ image }) => {
  const theme = useTheme();
  return (
    <Box
      className={classes.root}
      __vars={{ '--card-container-size': `${theme.other.cardContainerSize}px` }}
    >
      <ImageComponent image={image} isPriority style={{ height: 'auto' }} />
    </Box>
  );
};
