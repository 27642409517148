import { useEffect } from 'react';

import { PageCardAddedEvent } from '@/common/data/signal-r/SiteHub/_types/PageCardAddedEvent';
import { useLatest } from '@komo-tech/core/hooks/useLatest';
import { useFrontSite } from '@/front/components/site/SiteHost/Store';

import { CardUpdatedEvent, SiteHubMethods } from './_types';
import { useSiteHubSubscribe } from './useSiteHubSubscribe';

interface Options {
  onPublish?: (e: CardUpdatedEvent) => void;
  onAdded?: (e: PageCardAddedEvent) => void;
}
export const usePageCardsUpdatedSignalRSync = (options?: Options) => {
  const subscribe = useSiteHubSubscribe();

  const enabled = useFrontSite(
    (x) => x.site.properties.RealtimeContentUpdatesEnabled
  );

  const onPublishRef = useLatest(options?.onPublish);
  const onAddedRef = useLatest(options?.onAdded);
  useEffect(() => {
    if (!enabled) {
      return () => {};
    }

    const cardPublishedUnsubscribe = subscribe(
      SiteHubMethods.OnCardPublished,
      onPublishRef?.current
    );

    const pageCardsUnsubscribe = subscribe(
      SiteHubMethods.OnPageCardAdded,
      onAddedRef?.current
    );
    return () => {
      cardPublishedUnsubscribe();
      pageCardsUnsubscribe();
    };
  }, [enabled]);
};
